@import '@/sass/variables.scss';
@import '~vuetify/src/styles/styles.sass';

























































.steps-bar {
  grid-template-columns: repeat(auto-fit, minmax(50px, 50px));
  grid-gap: 10px;

  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }

  @media screen and (min-width: 1440px) {
    grid-gap: 30px;
  }
}
