@import '@/assets/scss/_variables.scss';

.PassVerification {
    .PassVerification_wrapper {
        padding: 24px 30px;
        background: #5abc67;
        box-shadow: 0px 18px 34px rgba(34, 39, 47, 0.1);
        border-radius: 4px;

        .PassVerification_container {
            width: 274px;
            max-width: 100%;
            margin: 0 auto;

            .PassVerification_head {
                font-size: 14px;
                line-height: 16px;
                color: #ffffff;
                margin-bottom: 14px;
            }

            .PassVerification_btn {
                width: 100%;
                padding: 16px;
                display: block;
                font-weight: bold;
                font-size: 12px;
                line-height: 14px;
                text-align: center;
                text-transform: uppercase;
                color: #0f1e30;
                background: #fff;
                cursor: pointer;
                outline: none;
                box-shadow: none;
                border-radius: 4px;

                &:disabled {
                    background: rgba(#fff, 0.8);
                    cursor: not-allowed;
                }
            }
        }
    }
}